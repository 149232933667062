
import { FormPage } from '@/mixins'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Component, Mixins } from 'vue-property-decorator'
import { TradingOptionsEditor } from '../business'
import BranchCategoryEditor from '../business/BranchCategoryEditor.vue'

@Component({
  components: {
    BranchCategoryEditor,
    TradingOptionsEditor
  }
})

export default class SignupTradingOptions extends Mixins(FormPage) {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch
}

