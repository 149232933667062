
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MascotAlert extends Vue {
  @Prop()
  private readonly img!: string;

  @Prop({ default: 'primary' })
  private readonly scheme!: string;
}
