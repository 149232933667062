var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nav-wrapper"},[_c('div',{staticClass:"logo--wrapper"},[_c('a',{attrs:{"href":_vm.logoUrl,"target":"_self"}},[_c('img',{staticClass:"logo",attrs:{"src":"/images/brand/tm-trans.png","alt":"Trading Mate Small Logo"}})])]),_c('ul',{staticClass:"steps-navigation"},[_c('li',{staticClass:"steps-navigation__item",class:{'clickable': _vm.currentIdx > 1, 'steps-navigation__item--done': _vm.currentIdx > 2,
   'steps-navigation__item--current': _vm.currentIdx === 2},on:{"click":function($event){return _vm.onClick(2)}}},[_c('p',[_vm._v(" Business Details ")])]),_c('li',{staticClass:"steps-navigation__item",class:{'clickable': _vm.currentIdx > 1, 'steps-navigation__item--done': _vm.currentIdx > 3,
   'steps-navigation__item--current': _vm.currentIdx === 3},on:{"click":function($event){return _vm.onClick(3)}}},[_vm._v(" Trading Options ")]),_c('li',{staticClass:"steps-navigation__item",class:{'clickable': _vm.currentIdx > 1,'steps-navigation__item--done': _vm.currentIdx > 4,
   'steps-navigation__item--current': _vm.currentIdx === 4},on:{"click":function($event){return _vm.onClick(4)}}},[_vm._v(" Contacts ")]),_c('li',{staticClass:"steps-navigation__item",class:{'clickable': _vm.currentIdx > 1,'steps-navigation__item--done': _vm.currentIdx > 5,
   'steps-navigation__item--current': _vm.currentIdx === 5},on:{"click":function($event){return _vm.onClick(5)}}},[_vm._v(" Images ")]),_c('li',{staticClass:"steps-navigation__item",class:{'clickable': _vm.currentIdx > 1,'steps-navigation__item--done': _vm.currentIdx > 6,
     'steps-navigation__item--current': _vm.currentIdx === 6},on:{"click":function($event){return _vm.onClick(6)}}},[_vm._m(0)]),_c('li',{staticClass:"steps-navigation__item"}),(_vm.currentIdx > 2)?_c('li',{staticClass:"steps-navigation__item back",class:{'clickable': _vm.currentIdx > 2},on:{"click":function($event){return _vm.onClick(_vm.currentIdx - 1)}}},[_vm._v(" Back ")]):_c('li',{staticClass:"steps-navigation__item"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"purple"},[_c('p',[_vm._v("Choose your advertising")])])
}]

export { render, staticRenderFns }