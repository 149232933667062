
import { FormPage } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { LicenseQualificationEditor, ServiceOfferedEditor } from '../business'
import TaglineEditor from '../business/TaglineEditor.vue'
import IntroductionEditor from '../business/IntroductionEditor.vue'
import BodyEditor from '../business/BodyEditor.vue'
import { UserModel } from 'tradingmate-modules/src/models/api/users'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'

@Component({
  components: {
    TaglineEditor,
    IntroductionEditor,
    BodyEditor,
    LicenseQualificationEditor,
    ServiceOfferedEditor
  }
})

export default class SignupBusinessDetail extends Mixins(FormPage) {
  private user: UserModel = this.$store.state.identity.User
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch
}

