
import ImageGallery from '@/components/inputs/ImageGallery.vue'
import { FormPage } from '@/mixins'
import { FileAttachmentType } from 'tradingmate-modules/src/models/api/files'
import AttachedObjectType from 'tradingmate-modules/src/models/api/files/AttachedObjectType'
import { Component, Mixins } from 'vue-property-decorator'
import { BusinessBranchModel } from '../../../../../tradingmate-modules/src/models/api/businesses'

@Component({
  components: {
    ImageGallery
  }
})

export default class LogoEditor extends Mixins(FormPage) {
  private branch: BusinessBranchModel | null = this.$store.state.identity.BusinessBranch
  private logoImageAttachType = AttachedObjectType.BusinessBranch
  private logoImageAttachmentType = FileAttachmentType.Logo
}

