
import { InputText } from '@/components/inputs'
import { FormPage } from '@/mixins'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import BusinessBranchIntroductionUpdateDto from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchIntroductionUpdateDto'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    InputText
  }
})

export default class IntroductionEditor extends Mixins(FormPage) {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch
  private dto: BusinessBranchIntroductionUpdateDto = { Introduction: this.branch?.Introduction ?? '' }

  mounted (): void {
    const bodystring = this.branch?.Introduction ? this.branch.Introduction : ''
    this.dto.Introduction = bodystring
  }

  private introductionDebounce: number | undefined = undefined
  UpdateDebounce (): void {
    window.clearTimeout(this.introductionDebounce)
    this.httpError = null
    this.introductionDebounce = window.setTimeout(() => {
      this.loading = true
      this.UpdateIntroduction()
    }, 1000)
  }

  UpdateIntroduction (): void {
    if (!this.branch) return
    this.loading = true
    Services.API.Businesses.UpdateIntroduction(this.branch.BusinessBranchId, this.dto)
      .then((intro) => {
        this.branch.Introduction = intro.Introduction
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.loading = false
      })
  }
}
