
import { InputText } from '@/components/inputs'
import { FormPage } from '@/mixins'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import BusinessBranchBodyUpdateDto from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchBodyUpdateDto'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    InputText
  }
})

export default class BodyEditor extends Mixins(FormPage) {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch
  private dto: BusinessBranchBodyUpdateDto = { Body: '' }
  private bodystring = ''

  mounted (): void {
    const bodystring = this.branch?.Body ? this.branch.Body.toString() : ''
    this.dto.Body = bodystring
  }

  private debounce: number | undefined = undefined
  UpdateDebounce (): void {
    window.clearTimeout(this.debounce)
    this.httpError = null
    this.debounce = window.setTimeout(() => {
      this.loading = true
      this.UpdateBody()
    }, 1000)
  }

  UpdateBody (): void {
    if (!this.branch) return
    this.loading = true
    console.log('update body')
    Services.API.Businesses.UpdateBody(this.branch.BusinessBranchId, this.dto)
      .then((value) => {
        this.branch.Body = value.Body
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.loading = false
      })
  }
}
