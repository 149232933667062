
import { InputText } from '@/components/inputs'
import { FormPage } from '@/mixins'
import { Config } from 'tradingmate-modules/src/environment'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import MascotAlert from './MascotAlert.vue'
import Verification from './Verification.vue'
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'
import { FieldCheckbox } from '@/components/forms/fields'
import SignUpModel from 'tradingmate-modules/src/models/api/signup/SignupModel'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { UserModel } from 'tradingmate-modules/src/models/api/users'

@Component({
  components: {
    MascotAlert,
    InputText,
    Verification,
    FormCheckboxField,
    FieldCheckbox
  }
})

export default class SignupUser extends Mixins(FormPage) {
  private HasWebsite = false
  private HasFacebook = false
  private HasABN = false
  private HasSocialLinks = false

  private hasCodeReceived = false

  private passesChecks = false
  private isCodeValid = false;
  private needsVerification = true
  private registerComplete = false

  private errors: string[] | null = null

  private signUpModel: SignUpModel = {
    BusinessName: '',
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    WebsiteUrl: '',
    FacebookUrl: '',
    Password: '',
    PasswordConfirmation: '',
    ABN: '',
    Telephone: '',
    PostCode: ''
  }

  checkRequirements (): void {
    this.loading = true
    console.log('Checking requirements')
    Services.API.Signup.CheckRequirements(this.signUpModel)
      .then((isValid) => {
        if (isValid)
        {
          console.log('Checking requirements passed')
          this.signup()
        } else {
          console.log('Checking requirements failed')
        }
      }).catch((errors) => {
        console.log('Checking requirements errors:')
        console.log(errors)
        this.httpError = errors
      }).finally(() => {
        this.loading = false
      })
  }

  signup (): void {
    Services.API.Signup.SignUpUserAndBusiness(this.signUpModel)
      .then((response) => {
        console.log('Sign up user and business success')
        this.signUserIn(response.User, response.BusinessBranch)
      }).catch((errors) => {
        this.httpError = errors
        console.log(errors)
      }).finally(() => {
        this.loading = false
      })
  }

  signUserIn (user: UserModel, branch: BusinessBranchModel): void {
    this.loading = true
    console.log('Signing in user')

    this.$store.commit('identity/setUser', user)
    this.$store.commit('identity/setBranch', branch)

    this.signIn()
  }

  signIn (): void {
    Services.API.Users.Login({
      Email: this.signUpModel.EmailAddress,
      Password: this.signUpModel.Password,
      ConsumerId: Config.OAuth.ConsumerId
    }).then((res) => {
      this.passesChecks = true
      console.log('Signing in user result:')
      console.log(res)
      if (res.NeedsVerification)
      {
        this.needsVerification = true
      }
      else if (res.Success) {
        Services.CredentialManager.SetOAuthCredential(res.OAuthToken)
        this.registerComplete = true
        this.needsVerification = false
      }
      else {
        console.log('Failed to sign in')
      }
    }).catch((errors) => {
      console.log('Sign user in errors:')
      console.log(errors)
      this.errors = errors
    }).finally(() => { this.loading = false })
  }

  onValidated (): void {
    console.log('Validation complete')
    this.signIn()
  }

  onContinue (): void {
    this.$emit('next')
  }

  onExpressSignup (): void {
    this.$emit('expressSignup')
  }
}

