
import { Component, Prop, Vue } from 'vue-property-decorator'
import Config from '@/config'

@Component
export default class StepsNavigation extends Vue {
  @Prop({ required: true })
  private readonly currentIdx!: number;

  onClick (index: number): void {
    if (this.currentIdx > 1) {
      this.$emit('clicked', index)
    }
  }

  get logoUrl (): string {
    return Config.WebsiteUrls.Public
  }

  onLogoClick (): void {
    window.open(Config.WebsiteUrls.Public)
  }
}
