
import { InputText } from '@/components/inputs'
import { FormPage } from '@/mixins'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import BusinessBranchHeadlineUpdateDto from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchHeadlineUpdateDto'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    InputText
  }
})

export default class TaglineEditor extends Mixins(FormPage) {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch
  private dto: BusinessBranchHeadlineUpdateDto = { Tagline: '' }

  mounted (): void {
    const bodystring = this.branch?.Tagline ? this.branch.Tagline : ''
    this.dto.Tagline = bodystring
  }

  private debounce: number | undefined = undefined
  UpdateDebounce (): void {
    window.clearTimeout(this.debounce)
    this.httpError = null
    this.debounce = window.setTimeout(() => {
      this.loading = true
      this.UpdateTagline()
    }, 1000)
  }

  UpdateTagline (): void {
    if (!this.branch) return
    this.loading = true
    Services.API.Businesses.UpdateHeadline(this.branch.BusinessBranchId, this.dto)
      .then((value) => {
        console.log('update headline')
        this.branch.Tagline = value.Tagline
        console.log('branch.Tagline')
        console.log(this.branch.Tagline)
      }).catch((error) => {
        console.log('error')
        console.log(error)
        this.httpError = error
      }).finally(() => {
        this.loading = false
      })
  }
}
