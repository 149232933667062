
import StepsNavigation from '@/components/collections/account/StepsNavigation.vue'
import SignupUser from '@/components/collections/account/SignupUser.vue'
import { FormPage } from '@/mixins'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Component, Mixins } from 'vue-property-decorator'
import SignupBusiness from '@/components/collections/account/SignupBusiness.vue'
import SignupBusinessDetail from '@/components/collections/account/SignupBusinessDetail.vue'
import SignupTradingOptions from '@/components/collections/account/SignupTradingOptions.vue'
import { ContactMethodEditor, SocialLinkEditor, TradingOptionsEditor } from '@/components/collections/business'
import BranchCategoryEditor from '@/components/collections/business/BranchCategoryEditor.vue'
import LogoEditor from '@/components/collections/business/LogoEditor.vue'
import GalleryEditor from '@/components/collections/business/GalleryEditor.vue'
import { UserModel } from 'tradingmate-modules/src/models/api/users'
import Membership from '@/views/dashboard/business/Membership.vue'
import PackageCheckout from '@/components/collections/account/PackageCheckout.vue'
import PackageCheckoutV4 from '@/components/collections/account/PackageCheckoutV4.vue'

@Component({
  components: {
    PackageCheckoutV4,
    PackageCheckout,
    StepsNavigation,
    SignupUser,
    SignupBusiness,
    SignupBusinessDetail,
    SignupTradingOptions,
    ContactMethodEditor,
    SocialLinkEditor,
    BranchCategoryEditor,
    TradingOptionsEditor,
    LogoEditor,
    GalleryEditor,
    Membership
  }
})
export default class Register extends Mixins(FormPage) {
  get branch (): BusinessBranchModel {
    return this.$store.state.identity.BusinessBranch
  }

  get user (): UserModel {
    return this.$store.state.identity.User
  }

  private stepIndex = 0;
  private validationErrors = false

  private choseToExpress = false

  mounted (): void {
    if (!this.branch) return
    // if branch is in draft mode then continue
    // if branch is anything else then just go to dashboard

    // const page = this.$route.query.page?.toString() ?? null
    // if (!page) return
    // const inn = Number.parseInt(page)
    // if (inn > 1 && inn < 6) {
    //   this.stepIndex = inn
    // }
    // get signup thing from store.
    // if there is an identity and a signup option then go to that page and continue
    const pagey: number = this.$store.state.register.Page
    console.log(pagey)
    if (pagey > 2 && pagey < 7)
    {
      this.stepIndex = pagey
      console.log(this.stepIndex)
    }
  }

  scrollToTop (): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  stepNav (step: number): void {
    this.stepIndex = step
    this.$store.state.register.Page = step
    this.scrollToTop()
  }

  skip (): void {
    this.scrollToTop()
    this.stepIndex = 6
  }

  onExpressSignUp (): void {
    this.choseToExpress = true
    this.$router.push({ query: { express: 'true', p: this.$route.query.p } })
    this.stepIndex = 6
  }

  onClicked (index: number): void {
    this.stepIndex = index
  }
}
